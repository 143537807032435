import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import * as Constants from "../data/constants";

export const Navigation = () => {
  const { t, i18n } = useTranslation();
  const options = [
    { value: "ES", label: (<div><img src="img/flags/es.svg" height="15px" style={{ paddingBottom: 2}} alt="Español"/> Español</div>)},
    { value: "BG", label: (<div><img src="img/flags/bg.svg" height="15px" style={{ paddingBottom: 2}} alt="Български"/> Български</div>)},
    { value: "EN", label: (<div><img src="img/flags/en.svg" height="15px" style={{ paddingBottom: 2}} alt="English"/> English</div>)},
  ];
  
  const selected_option = sessionStorage.getItem("language") ? options.filter(x => x.value === sessionStorage.getItem("language")) : options.filter(x => x.value === "ES");
  
  const changeLanguageHandler = (e) => {
    sessionStorage.setItem("language", e.value);
    i18n.changeLanguage(e.value);

    document.title = t('Title');
  }
  
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <a href="/"><img src="img/logo.png" alt="logo" className="logo"></img></a>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li><a href={"tel:" + Constants.phone} className="darkBlueColor"><i className="fa fa-phone"></i> {Constants.phone}</a></li>
            <li><a href={"mailto:" + Constants.email} style={{ textTransform: 'none'}} className="darkBlueColor"><i className="fa fa-regular fa-envelope"></i> {Constants.email}</a></li>
            <li>
              <Select className="custom-select darkBlueColor" isSearchable={false} value={selected_option} options={options} onChange={changeLanguageHandler}/>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li><a href="/#index" className="page-scroll">{t('Navigation.home')}</a></li>
            <li><a href="/#about" className="page-scroll">{t('Navigation.about')}</a></li>
            <li><a href="/#services" className="page-scroll">{t('Navigation.services')}</a></li>
            <li><a href="https://eghvgservices.wixsite.com/e-g-h-i-v-g-servicio" className="page-scroll">{t('Navigation.fiscal')}</a></li>
            <li><a href="/blog" className="page-scroll">{t('Navigation.blog')}</a></li>
            <li><a href="/#contact" className="page-scroll">{t('Navigation.contact')}</a></li> 
          </ul>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        </div>
      </div>
    </nav>
  );
};
