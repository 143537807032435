import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dataES from "./data/es-ES.json";
import dataBG from "./data/bg-BG.json";
import dataEN from "./data/en-US.json";

const resources = {
  ES: {
    translation: dataES,
  },
  BG: {
    translation: dataBG,
  },
  EN: {
    translation: dataEN,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "ES",
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;