import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Home from './Home';
import Blog from './Blog';
import Privacy from "./Privacy";
import TelegramService from "./services/telegramService.js";
import CookieConsent from "react-cookie-consent";

const App = () => {
    const { t } = useTranslation();
    document.title = t('Title');

    const getIp = async () => {

        try {
            const sent = sessionStorage.getItem("analyticsSent") ? sessionStorage.getItem("analyticsSent") : false;

            if (!sent) {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json()

                TelegramService.analytics(data)
                    .then(() => {
                        sessionStorage.setItem("analyticsSent", true);
                    });
            }
        }
        catch (e) {
            console.log(e);
            console.log("Something went wrong...");
        }
    }

    getIp();

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/privacy" element={<Privacy />} />
                </Routes>
            </Router>

            <CookieConsent
                location="bottom"
                buttonText={t('Privacy.cookieAcceptBtn')}
                style={{ background: "#20232e" }}
                buttonStyle={{ backgroundColor: "#D4AF37", color: "#000", fontSize: "15px" }}
                expires={365}
            >
        <span style={{ fontSize: "15px" }}>
          {t('Privacy.cookieText')}{" "}
            <a href="/privacy">{t('Privacy.cookieLinkText')}</a>
        </span>
            </CookieConsent>
        </div>
    );
};

export default App;
