import React from "react";
import { useTranslation } from "react-i18next";

export const Services = (props) => {
  const { t } = useTranslation();

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("Services.title")}</h2>
          <p>{t("Services.paragraph")}</p>          
        </div>
        <div className="row">
          {t('Services.services', { returnObjects: true}).map((d, i) => (
              <div key={i} className="col-md-4 col-xs-12">
                {" "}
                <i className={d.icon}></i>
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>

                  <dl style={{ height: "100px"}}> 
                    {t('Services.services.' + i + '.bullets', {returnObjects: true}).map((f, j) => (
                      j < 3 ?
                        <li key={j}><span>{f}</span></li>
                      : null
                    ))}
                  </dl>

                  <button className="btn btn-custom btn-lg" data-toggle="modal" data-target={"#myModal" + i}>{t('Modal.open')}</button>

                  <div id={"myModal" + i} className="modal fade" role="dialog">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal">&times;</button>
                          <h4 className="modal-title">{d.name}</h4>
                        </div>
                        <div className="modal-body">
                          <dl className="" style={{textAlign: "left"}}> 
                            {t('Services.services.' + i + '.bullets', {returnObjects: true}).map((f, j) => (
                              <li key={j}><span>{f}</span></li>
                            ))}
                          </dl>   
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-custom" data-dismiss="modal">{t('Modal.close')}</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
