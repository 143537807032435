import React from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Index } from "./components/index";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import "./i18n";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {  
  return (
    <div>
      <Navigation />
      <Header />
      <Index />
      <About />
      <Services />
      <Contact />
    </div>
  );
};

export default Home;
