import React from "react";
import { useTranslation } from "react-i18next";

export const Index = (props) => {
  const { t } = useTranslation();
  
  return (
    <div id="index" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>{t('Index.title')}</h2> 
        </div>
        <div className="row">
          {t('Index.indexes', { returnObjects: true }).map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-xs-12 col-md-3">
              {" "}
              <i className={d.icon}></i>
              <h3>{d.title}</h3>
              <p>{d.text}</p>
            </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
